import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToTop = (): void => {
  const location = useLocation();

  useEffect(() => {
    // scroll to top
    document.getElementById("app-page")!.scrollTo(0, 0);
    window.scroll({ behavior: "smooth" });
  }, [location]);
};
