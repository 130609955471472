import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./Showcase.scss";
import { IFeaturedContent as FeaturedContentProps } from "./interfaces";

export const FeaturedContentTemplate: React.FC<FeaturedContentProps> = ({ title, details, img, rev }) => {
  const [rev1, setRev] = useState(rev);
  let listener: any;

  useEffect(() => {
    listener = window.addEventListener("resize", updateRev);

    return () => {
      window.removeEventListener("resize", updateRev);
    };
  }, []);

  const updateRev = (): void => {
    const tempVal = window.innerWidth < 768 ? false : rev;
    if (window.innerWidth < 768 && !!(window.innerWidth < 768) !== rev) {
      setRev(tempVal);
    }
  };

  return (
    <Row className="p-4 my-l justify-content-center align-items-center">
      {img && (
        <Col
          md={{ span: 6, order: !rev1 ? 0 : 1 }}
          sm={{ span: 10, order: !rev1 ? 0 : 1 }}
          xs={{ span: 10, order: !rev1 ? 0 : 1 }}
          lg={{ span: 4, order: !rev1 ? 0 : 1 }}
          xl={{ span: 3, order: !rev1 ? 0 : 1 }}
          className="my-2"
        >
          <Image src={img} width="100%" rounded></Image>
        </Col>
      )}
      <Col md={12} sm={12} lg={{ span: img ? 7 : 10 }} xl={{ span: img ? 6 : 8 }} className={`${rev1 && "text-right"}`}>
        <h3>{title}</h3>
        {details.map((p, i) => {
          return <p key={i}>{p}</p>;
        })}
      </Col>
    </Row>
  );
};
