import { Container } from "react-bootstrap";
import Router from "./module/navigation";
import React, { useEffect } from "react";

const App: React.FC = () => {
  useEffect(() => {
    setBodyHeight();
    window.addEventListener("resize", setBodyHeight);

    return () => {
      window.removeEventListener("resize", setBodyHeight);
    };
  }, []);

  const setBodyHeight = () => {
    document.getElementById("app-page")!.style.height = `${window.innerHeight}px`;
  };

  return (
    <Container fluid className="app-body scroll p-0" id="app-page">
      <Router />
    </Container>
  );
};

export default App;
