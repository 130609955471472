import React from "react";
import Image from "react-bootstrap/Image";
import { IconText } from "../../shared/component/text";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { _email, _roleTitle, _profileSummary } from "../profile/profileText";
import { Col, Row } from "react-bootstrap";

const ProfileCardTemplate: React.FC = () => {
  return (
    <Row className="p-4 justify-content-center">
      <Col md={4} sm={4} xs={3} lg={3} xl={2} className="d-flex align-items-center">
        <Image src={require("../../assets/images/avatar.jpg")} width="100%" style={{ maxWidth: "200px" }} roundedCircle />
      </Col>
      <Col md="auto" sm={8} xs={9} lg="auto" xl={8} className="d-flex flex-column justify-content-center align-items-start">
        <div className="text-left">
          <span className="font-weight-bold">Kristian Antrobus</span> <br />
          {_roleTitle}
          <IconText icon={faEnvelope} text={_email} center="true" />
        </div>
        <div className="d-none d-xl-block d-lg-block mt-2">
          {_profileSummary.map((personalStatement, index) => (
            <p key={index}>{personalStatement}</p>
          ))}
        </div>
      </Col>
      <Col md="auto" className="mt-4 d-xl-none d-lg-none">
        {_profileSummary.map((personalStatement, index) => (
          <p key={index}>{personalStatement}</p>
        ))}
      </Col>
    </Row>
  );
};

export default ProfileCardTemplate;
