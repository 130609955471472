import "./wave.scss";
import { Container } from "react-bootstrap";

const wave1 = (bgClass: string) => (
  <svg id="wave0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      className={bgClass}
      d="M0,64L48,58.7C96,53,192,43,288,37.3C384,32,480,32,576,53.3C672,75,768,117,864,154.7C960,192,1056,224,1152,240C1248,256,1344,256,1392,256L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
    ></path>
  </svg>
);

const wave2 = (bgClass: string) => (
  <svg id="wave1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      className={bgClass}
      d="M0,224L48,213.3C96,203,192,181,288,176C384,171,480,181,576,165.3C672,149,768,107,864,96C960,85,1056,107,1152,138.7C1248,171,1344,213,1392,234.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
    ></path>
  </svg>
);

const wave3 = (bgClass: string) => (
  <svg id="wave2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      className={bgClass}
      d="M0,288L48,293.3C96,299,192,309,288,298.7C384,288,480,256,576,245.3C672,235,768,245,864,245.3C960,245,1056,235,1152,213.3C1248,192,1344,160,1392,144L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
    ></path>
  </svg>
);

const WaveClip = ({ bg = "bg-body-bg", wave = 0 }) => {
  const waves = [wave1(bg), wave2(bg), wave3(bg)];
  return (
    <Container
      fluid
      className={`wave`}
      style={{
        marginTop: "-5px",
        clipPath: `url(#wave${wave})`,
      }}
    >
      {waves[wave]}
    </Container>
  );
};
export default WaveClip;
