import React, { useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import "./Showcase.scss";
import { IShowcase, IShowcase as ShowcaseProps, ITechnology } from "./interfaces";
import Skills from "../../shared/component/skills";
import AppStoreButton from "../../shared/component/AppStoreButton";
import { navigateNewTab } from "../../shared/service/navigate";

const ShowcaseMoreDetails: React.FC<ShowcaseProps> = ({ desc, tech, title, appStore, link, playStore, repo }) => {
  return (
    <Col
      xs={12}
      sm={12}
      md={11}
      lg={11}
      xl={10}
      className={`my-4 p-4 ${!title && "hide"} flex-column bg-body-bg rounded`}
      id="showcaseDetails"
    >
      <h5 className="pb-3">{title}</h5>
      {tech && <Skills skills={tech.map((tech: ITechnology) => tech.name)} align="start" reverse={true}></Skills>}
      <div className="py-4">
        {desc &&
          desc.map((d, idx) => {
            return <p key={`SCDetails${idx}}`}>{d}</p>;
          })}
      </div>
      <Row className="justify-content-center">
        {(repo || link) && (
          <Col sm={12} md={6} className="d-flex justify-content-center">
            {repo && (
              <Button variant="outline-primary" onClick={() => navigateNewTab(repo)} href={repo} target="_blank">
                Repository
              </Button>
            )}
            {link && (
              <Button variant="outline-primary" onClick={() => navigateNewTab(link)}>
                View
              </Button>
            )}
          </Col>
        )}
        {(playStore || appStore) && (
          <Col sm={12} md={6} className="d-flex justify-content-center">
            {playStore && <AppStoreButton link={playStore} store="Google" />}
            {appStore && <AppStoreButton link={appStore} store="Apple" />}
          </Col>
        )}
      </Row>
    </Col>
  );
};

const TechPills = ({ techs }: { techs: ITechnology[] }) => {
  return (
    <div id="techPills">
      {techs.map((tech: ITechnology, idx: number) => {
        return (
          <div
            className="pill d-flex align-items-center mb-2 py-1"
            key={`techPill${idx}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Image src={tech.img} roundedCircle className="px-1" width="30px"></Image>
            <div className="text-white ml-3 pr-4">{tech.name}</div>
          </div>
        );
      })}
    </div>
  );
};

interface ShowcaseTileProps extends IShowcase {
  showDesc: () => void;
  selected: boolean;
  id: string;
}

const ShowcaseTile: React.FC<ShowcaseTileProps> = ({ selected, img, tech, showDesc, id }) => (
  <Col
    xs={6}
    sm={6}
    md={4}
    lg={3}
    xl={3}
    style={{}}
    className={`showcaseTile p-1 ${selected ? "selected" : ""}`}
    id={id}
    onClick={() => {
      showDesc();
    }}
  >
    <Image src={img} width="100%" rounded></Image>
    <TechPills techs={tech.slice(0, window.innerWidth < 700 ? 4 : 5)} />
  </Col>
);

export const ShowcaseTiles: React.FC<{ projects: IShowcase[] }> = ({ projects }) => {
  const [showDetails, setShowDetails] = useState<IShowcase | null>(null);

  const determineIndexForSiblingAddition = (idx: number) => {
    const width = window.innerWidth;

    const getIndexOfLastSiblingInRow = (colLength: number): number => {
      const lastIndexInRow = Math.ceil(idx / colLength) * colLength;
      if (lastIndexInRow > projects.length) {
        return projects.length;
      } else {
        return lastIndexInRow;
      }
    };

    switch (true) {
      // xl
      case width >= 1200:
        return getIndexOfLastSiblingInRow(4);
      // lg
      case width >= 992:
        return getIndexOfLastSiblingInRow(4);
      // md
      case width >= 768:
        return getIndexOfLastSiblingInRow(3);
      // sm & xs
      case width < 768:
        return getIndexOfLastSiblingInRow(2);
    }
  };

  const checkDescriptionOutOfView = () => {
    const el = document.getElementById("showcaseDetails");
    const elRect = el!.getBoundingClientRect();
    //  Check if bottom of element out of viewport
    // scroll to top of element using el.offset then + height of tile
    if (window.innerHeight < elRect.bottom) {
      document
        .getElementById("app-page")!
        .scrollTo(0, el!.offsetTop - document.getElementsByClassName("showcaseTile")[0].clientHeight - 30);
    }
  };

  const showDescription = (project: ShowcaseProps, idx: number, id: string) => {
    if (document.getElementById(id)!.classList.contains("selected")) {
      setShowDetails(null);
      document.getElementById("showcaseDetails")!.classList.remove("show");
    } else {
      setShowDetails(null);
      document.getElementById("showcaseDetails")!.classList.remove("show");

      setTimeout(() => {
        document
          .getElementById("tileContainer")!
          .insertBefore(
            document.getElementById("showcaseDetails")!,
            document.getElementById(`SC${determineIndexForSiblingAddition(idx)}`)!.nextSibling
          );
        setShowDetails(project);

        setTimeout(() => {
          document.getElementById("showcaseDetails")!.classList.add("show");
        }, 100);
        setTimeout(() => {
          checkDescriptionOutOfView();
        }, 300);
      }, 300);
    }
  };

  return (
    <Row className="p-4 justify-content-center" id="tileContainer">
      {projects.map((p, index) => {
        return (
          <ShowcaseTile
            {...p}
            key={`showcase-card-${index + 1}`}
            id={`SC${index + 1}`}
            showDesc={() => showDescription(p, index + 1, `SC${index + 1}`)}
            selected={showDetails === p}
          ></ShowcaseTile>
        );
      })}
      {/* @ts-ignore */}
      <ShowcaseMoreDetails {...showDetails}></ShowcaseMoreDetails>
    </Row>
  );
};
