import "./styles.scss";
import React from "react";
import { Skills } from "../../shared/component/skills";
import {
  _experienceList,
  _educationList,
  _cvSkills,
  _profileSummary,
  _personalMeta,
  _interest,
  _certifications,
} from "./profileText";
import { Container, Row, Col, Tooltip, OverlayTrigger, Image } from "react-bootstrap";
import Experience from "./Experience";
import { IEducation as EducationProps, ICertification } from "./interfaces";
import { useScrollToTop } from "../../shared/hooks/scrollToTop";

const Certifications: React.FC<{ certifications: ICertification[] }> = ({ certifications }) => (
  <Row className="justify-content-center my-4 ">
    {certifications.map((cert, index) => {
      return (
        <Col lg={3} xs={4} sm={4} key={`${index}-employers`} className="d-flex align-items-center">
          <OverlayTrigger placement="top" overlay={<Tooltip id={`employer-tooltip-${index}`}>{cert.name}</Tooltip>}>
            <Image src={cert.img} width="98%"></Image>
          </OverlayTrigger>
        </Col>
      );
    })}
  </Row>
);

const Education: React.FC<EducationProps> = ({ duration, body, qualification, details }) => {
  return (
    <div className="d-flex flex-column mb-2">
      <span className="small">{duration}</span>
      <p style={{ marginBottom: 0 }}>
        <strong>{qualification}</strong>
      </p>
      <p>
        <span className="font-italic">{body}</span>
      </p>
      {details?.map((detail) => {
        return <p key={detail}>{detail}</p>;
      })}
    </div>
  );
};

const Profile: React.FC = (): JSX.Element => {
  useScrollToTop();

  return (
    <Container fluid className="bg-white m-0 p-0">
      <Container className="py-4 h-100">
        <Row className="flex-column px-2">
          <h2 className="w-100 mb-2">Kristian Antrobus</h2>
          <h4 className="w-100 cv-header">Full Stack Developer</h4>
          <h4>Profile</h4>
          {_profileSummary.map((p, i) => {
            return <p key={`summary-${i}`}>{p}</p>;
          })}
          <h4 className="w-100 cv-header"></h4>
        </Row>
        <Row className="px-2">
          <Col md={4} sm={12} className="left-bar">
            <h4 className="mb-4">Contact</h4>
            {Object.keys(_personalMeta).map((key, i) => {
              return (
                <p key={`personal-${i}`}>
                  <strong>{key}</strong>: {_personalMeta[key]}
                </p>
              );
            })}

            <Certifications certifications={_certifications} />

            <h4 className="my-2">Skills</h4>
            {_cvSkills.map((skill, i) => (
              <div className="mb-4" key={`skills-${i}`}>
                <p className="mb-0">{skill.yearCompetency}+ Yrs</p>
                <Skills skills={skill.skills} align="start" />
              </div>
            ))}

            <h4 className="my-2">Education</h4>
            {_educationList.map((ed) => {
              return <Education {...ed} key={ed.qualification} />;
            })}

            <h4 className="my-2">Interests</h4>
            {_interest.map((interest, index) => {
              return <p key={`interest-${index}`}>{interest}</p>;
            })}
          </Col>
          <Col md={8} sm={12}>
            <h4 className="mb-4">Experience</h4>
            {_experienceList.map((exp, i) => {
              return <Experience {...exp} key={`${exp.title}${i}`} />;
            })}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Profile;
