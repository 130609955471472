import "./skills.scss";

interface SkillComp {
  skills: string[];
  limit?: number;
  small?: boolean;
  align?: string;
  reverse?: boolean;
}

export const Skills = ({ skills = [], limit, small = true, align = "center", reverse = false }: SkillComp) => {
  const skillsArr: string[] = limit ? [...skills].splice(0, limit) : skills;
  return (
    <div className={`d-flex flex-wrap justify-content-${align} w-100 ${small && "small"}`}>
      {skillsArr.map((skill) => {
        return (
          <div className={`skill-pill ${reverse && "reverse"}`} key={skill}>
            {skill}
          </div>
        );
      })}
    </div>
  );
};

export default Skills;
