import React from "react";
import { Image } from "react-bootstrap";
import { navigateNewTab } from "../service/navigate";

type StorePlatform = "Google" | "Apple";

interface AppStoreProps {
  store: StorePlatform;
  link: string;
}

export const AppStoreButton: React.FC<AppStoreProps> = ({ store, link }) => {
  const getStoreImg = (): string => {
    switch (store) {
      case "Google":
        return require("../../assets/images/showcase/download-playstore.png");
      case "Apple":
        return require("../../assets/images/showcase/download-appstore.png");
      default:
        return "";
    }
  };

  return <Image src={getStoreImg()} onClick={() => navigateNewTab(link)} className="me-4" height="40px"></Image>;
};

export default AppStoreButton;
