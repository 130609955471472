import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "./PersonalDetails";
import { _experienceList, _skillsList, _employers } from "../profile/profileText";
import Experience from "../profile/Experience";
import { Button, Col, Container, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Skills from "../../shared/component/skills";
import WaveClip from "../../shared/component/wave";
import { _featuredHomeContent, _showcaseFeature } from "./indexText";
import { FeaturedContentTemplate } from "./FeaturedDetails";
import { ShowcaseTiles } from "./Showcase";
import { _angularProjects } from "./ShowcaseText";
import { useScrollToTop } from "../../shared/hooks/scrollToTop";

const Employers = () => {
  return (
    <Row className="justify-content-center mx-4 ">
      {_employers.map((employer, index) => {
        return (
          <Col xs={3} xl={1} lg={2} md={2} sm={3} key={`${index}-employers`} className="d-flex my-2 align-items-center">
            <OverlayTrigger placement="top" overlay={<Tooltip id={`employer-tooltip-${index}`}>{employer.name}</Tooltip>}>
              <Image src={employer.img} width="95%"></Image>
            </OverlayTrigger>
          </Col>
        );
      })}
    </Row>
  );
};

const Home = () => {
  useScrollToTop();
  return (
    <Container fluid>
      <Row className="bg-splash-1 text-light" id="">
        <Container className="mt-2">
          <ProfileCard />
        </Container>
      </Row>
      <Row className="bg-splash-2 text-light">
        <WaveClip wave={1} bg="bg-splash-1" />
        <Container>
          <Row className="p-4">
            <Col lg={{ span: 6 }} md={12} sm={12} className="d-flex flex-column justify-content-center my-4">
              <h3 className="text-center mb-4">Most Recent Experience</h3>
              <Experience {..._experienceList[1]} />
              <Link to="/profile" className="d-flex justify-content-center">
                <Button variant="outline-primary">View full CV</Button>
              </Link>
            </Col>
            <Col
              md={12}
              lg={{ span: 5, offset: 1 }}
              sm={12}
              className="d-flex align-items-center flex-column justify-content-center my-4"
            >
              <h3>Top Skills</h3>
              <Skills skills={_skillsList} limit={30} small={false} />
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="bg-body-bg text-dark">
        <WaveClip wave={0} bg="bg-splash-2" />

        <Container>
          <Employers />
          {_featuredHomeContent.map((el, idx) => {
            return <FeaturedContentTemplate key={`featuredContent-${idx}`} {...el} />;
          })}
        </Container>
      </Row>
      <Row className="bg-splash-3">
        <WaveClip wave={2} bg="bg-body-bg" />
        <Container>
          <FeaturedContentTemplate {..._showcaseFeature} />
          <ShowcaseTiles projects={_angularProjects}></ShowcaseTiles>
        </Container>
      </Row>
    </Container>
  );
};

export default Home;
