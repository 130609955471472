import { IExperience as ExperienceProps } from "./interfaces";

export const Experience: React.FC<ExperienceProps> = ({ details, duration, employer, title, location }) => {
  return (
    <div className="d-flex flex-column mb-4">
      <span className="small">{duration}</span>
      <p style={{ marginBottom: 0 }}>
        <strong>{employer}</strong>
      </p>
      <p>
        <strong>
          <em>{title}</em>
        </strong>
        {location && ` , ${location}`}
      </p>

      {details.map((detail: string, i) => {
        return <p key={`exp-${i}`}>{detail}</p>;
      })}
    </div>
  );
};

export default Experience;
