import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./home";
import Profile from "./profile";
import PrivacyPolicyFinanceTracker from "./policies/PrivacyPolicyFinanceTracker";
import { useEffect } from "react";

export const RoutePaths = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile/" element={<Profile />} />
        <Route path="/privacy-policy-finance-tracker/" element={PrivacyPolicyFinanceTracker} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutePaths;

// import React, { Component } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFile } from "@fortawesome/free-regular-svg-icons";
// import { faHome, faPuzzlePiece, faUser } from "@fortawesome/free-solid-svg-icons";
// import Profile from "../pages/Profile";
// import { Route, NavLink, withRouter } from "react-router-dom";
// import "../styles/navbar.scss";
// import PrivacyPolicyFinanceTracker from "../pages/privacy-policy/PrivacyPolicyFinanceTracker";
// import Home from "../pages/Home";

// class ScrollToTopCompoennt extends Component {
//   componentDidUpdate(prevProps) {
//     // Internet Explorer 6-11
//     //@ts-ignore
//     const isIE = /*@cc_on!@*/ false || !!document.documentMode;
//     // Edge 20+
//     //@ts-ignore
//     const isEdge = !isIE && !!window.StyleMedia;
//     //@ts-ignore
//     if (this.props.location !== prevProps.location) {
//       !(isIE || isEdge) && document.getElementById("app-page").scrollTo(0, 0);
//     }
//     window.scroll({ behavior: "smooth" });
//   }

//   render() {
//     return this.props.children;
//   }
// }

// export const ScrollToTop = withRouter(ScrollToTopCompoennt);

// export const RoutePaths = () => {
//   return (
//     <>
//       <Route path="/" exact component={Home} />
//       <Route path="/profile/" component={Profile} />
//       <Route path="/privacy-policy-finance-tracker/" component={PrivacyPolicyFinanceTracker} />
//     </>
//   );
// };

// const SideNavIcon = ({ icon = faFile }) => {
//   return (
//     <div
//       className="text-center d-flex justify-content-center align-items-center navIcon"
//       style={{ height: "5rem", fontSize: ".9em" }}
//     >
//       <FontAwesomeIcon icon={icon} />
//     </div>
//   );
// };

// const BottomNavIcon = ({ icon = faFile, text = "page", showText = true }) => {
//   return (
//     <div className="text-center d-flex justify-content-center flex-column align-items-center" style={{ height: "2rem" }}>
//       <FontAwesomeIcon icon={icon} />
//       {/* {showText && <div className="small">{text}</div>} */}
//     </div>
//   );
// };

// export const NavBar = () => {
//   return (
//     <>
//       <div className="bg-side-color-light flex-column d-none d-md-flex" style={{ width: "30px", minWidth: "30px" }}>
//         <NavLink to="/" activeClassName="active-route" className="navbar-lg-tab" exact>
//           <SideNavIcon icon={faHome} />
//         </NavLink>
//         <NavLink to="/showcase" activeClassName="active-route" className="navbar-lg-tab">
//           <SideNavIcon icon={faPuzzlePiece} />
//         </NavLink>
//         <NavLink to="/profile" activeClassName="active-route" className="navbar-lg-tab">
//           <SideNavIcon icon={faUser} />
//         </NavLink>
//       </div>
//       <div className="bg-side-color-light fixed-bottom navbar navbar-expand justify-content-around d-flex d-block d-md-none">
//         <NavLink to="/" activeClassName="active-route-mob" className="navbar-sm-tab" exact>
//           <BottomNavIcon icon={faHome} text="Home" />
//         </NavLink>
//         <NavLink to="/showcase" activeClassName="active-route-mob" className="navbar-sm-tab" exact>
//           <BottomNavIcon icon={faPuzzlePiece} text="Showcase" />
//         </NavLink>
//         <NavLink to="/profile" className="navbar-sm-tab" activeClassName="active-route-mob">
//           <BottomNavIcon icon={faUser} text="Profile" />
//         </NavLink>
//       </div>
//     </>
//   );
// };
