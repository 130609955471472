import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";

export const IconText = ({
  icon = faFile,
  text = "please enter text",
  center = "false",
}) => {
  return (
    <div
      className="d-flex align-items-center p-0"
      style={
        center === "true"
          ? { justifyContent: "center" }
          : { wordWrap: "break-word" }
      }
    >
      <FontAwesomeIcon icon={icon} style={{ marginRight: "0.5rem" }} />
      {text}
    </div>
  );
};
